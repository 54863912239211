import React from "react";
import logo from "../img/logo.webp";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Our Projects", href: "/projects", current: false },
  {
    name: "Contact us",
    href: "https://www.rashidaljabrigroup.com/contactus",
    target: "_blank",
    rel: "noopener noreferrer",
    current: false,
  },
  {
    name: "Soft Launch",
    href: "https://in.frms.link/z4h0kzc/",
    target: "_blank",
    rel: "noopener noreferrer",
    current: false,
  },
  {
    name: "RSJ Group",
    href: "https://www.rashidaljabrigroup.com/",
    target: "_blank",
    rel: "noopener noreferrer",
    current: false,
  },
];

const contact = [
  {
    icon: [
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sm:w-5 sm:h-5 w-4 h-5"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M16.6667 3.33334H3.33333C2.41667 3.33334 1.675 4.08334 1.675 5.00001L1.66667 15C1.66667 15.9167 2.41667 16.6667 3.33333 16.6667H16.6667C17.5833 16.6667 18.3333 15.9167 18.3333 15V5.00001C18.3333 4.08334 17.5833 3.33334 16.6667 3.33334ZM16.6667 6.66668L10 10.8333L3.33333 6.66668V5.00001L10 9.16668L16.6667 5.00001V6.66668Z"
          fill="#ffffff"
        />
      </svg>,
    ],
    name: "info@rashedaljabridevelopment.com",
    href: "mailto:info@rashedaljabridevelopment.com",
    current: false,
  },
  {
    icon: [
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sm:w-5 sm:h-5 w-4 h-5"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M13.7967 10.755L13.4175 11.1325C13.4175 11.1325 12.515 12.0292 10.0525 9.58084C7.58999 7.1325 8.49249 6.23584 8.49249 6.23584L8.73082 5.9975C9.31999 5.4125 9.37582 4.4725 8.86166 3.78584L7.81166 2.38334C7.17499 1.53334 5.94582 1.42084 5.21666 2.14584L3.90832 3.44584C3.54749 3.80584 3.30582 4.27084 3.33499 4.7875C3.40999 6.11 4.00832 8.95417 7.34499 12.2725C10.8842 15.7908 14.205 15.9308 15.5625 15.8042C15.9925 15.7642 16.3658 15.5458 16.6667 15.2458L17.85 14.0692C18.65 13.275 18.425 11.9125 17.4017 11.3567L15.81 10.4908C15.1383 10.1267 14.3217 10.2333 13.7967 10.755Z"
          fill="#ffffff"
        />
      </svg>,
    ],
    name: "+971 43994479",
    href: "tel:+97143994479",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  return (
    <div>
      <div className="bg-black py-2">
        <div className="flex md:justify-end justify-between md:space-x-10 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-xs text-white">
          {contact.map((item) => (
            <a
              key={item.name}
              href={item.href}
              aria-current={item.current ? "page" : undefined}
              className="flex justify-center items-center gap-1"
            >
              {item.icon}
              {item.name}
            </a>
          ))}
        </div>
      </div>

      <Disclosure as="nav" className="bg-white">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex py-2 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button*/}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block h-6 w-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-6 w-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
              <div className="flex flex-shrink-0 items-center">
                <a href="/">
                  <img
                    alt="rashed al jabri real estate development"
                    src={logo}
                    className="h-12 w-auto"
                  />
                </a>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={item.target}
                      rel={item.rel}
                      aria-current={item.current ? "page" : undefined}
                      className={classNames(
                        item.icon
                          ? "flex justify-center items-center gap-1"
                          : "",
                        "rounded-md px-3 py-2 font-medium"
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="sm:flex hidden justify-start items-center gap-2 px-3 py-1 rounded-[20px] bg-white border border-black hover:bg-black text-black hover:text-white transition ease-in-out delay-50 duration-300 cursor-pointer">
                  <svg
                    width={25}
                    height={26}
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[25px] h-[25px]"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M19.8437 5.61459C18.8887 4.64993 17.7512 3.88508 16.4975 3.36465C15.2438 2.84421 13.8991 2.57862 12.5417 2.58334C6.85416 2.58334 2.21874 7.21876 2.21874 12.9063C2.21874 14.7292 2.69791 16.5 3.59374 18.0625L2.13541 23.4167L7.60416 21.9792C9.11457 22.8021 10.8125 23.2396 12.5417 23.2396C18.2292 23.2396 22.8646 18.6042 22.8646 12.9167C22.8646 10.1563 21.7917 7.56251 19.8437 5.61459ZM12.5417 21.4896C11 21.4896 9.48957 21.0729 8.16666 20.2917L7.85416 20.1042L4.60416 20.9583L5.46874 17.7917L5.26041 17.4688C4.40389 16.101 3.94909 14.5201 3.94791 12.9063C3.94791 8.17709 7.80207 4.32293 12.5312 4.32293C14.8229 4.32293 16.9792 5.21876 18.5937 6.84376C19.3932 7.63956 20.0268 8.58612 20.4577 9.62858C20.8886 10.671 21.1084 11.7887 21.1042 12.9167C21.125 17.6458 17.2708 21.4896 12.5417 21.4896ZM17.25 15.0729C16.9896 14.9479 15.7187 14.3229 15.4896 14.2292C15.25 14.1458 15.0833 14.1042 14.9062 14.3542C14.7292 14.6146 14.2396 15.1979 14.0937 15.3646C13.9479 15.5417 13.7917 15.5625 13.5312 15.4271C13.2708 15.3021 12.4375 15.0208 11.4583 14.1458C10.6875 13.4583 10.1771 12.6146 10.0208 12.3542C9.87499 12.0938 9.99999 11.9583 10.1354 11.8229C10.25 11.7083 10.3958 11.5208 10.5208 11.375C10.6458 11.2292 10.6979 11.1146 10.7812 10.9479C10.8646 10.7708 10.8229 10.625 10.7604 10.5C10.6979 10.375 10.1771 9.10418 9.96874 8.58334C9.76041 8.08334 9.54166 8.14584 9.38541 8.13543H8.88541C8.70832 8.13543 8.43749 8.19793 8.19791 8.45834C7.96874 8.71876 7.30207 9.34376 7.30207 10.6146C7.30207 11.8854 8.22916 13.1146 8.35416 13.2813C8.47916 13.4583 10.1771 16.0625 12.7604 17.1771C13.375 17.4479 13.8542 17.6042 14.2292 17.7188C14.8437 17.9167 15.4062 17.8854 15.8542 17.8229C16.3542 17.75 17.3854 17.1979 17.5937 16.5938C17.8125 15.9896 17.8125 15.4792 17.7396 15.3646C17.6667 15.25 17.5104 15.1979 17.25 15.0729Z"
                      fill="#06A88D"
                    />
                  </svg>
                  <p className="text-sm">
                    <a href="https://wa.me/97143994479" target="_blank">
                      WhatsApp
                    </a>
                  </p>
                </div>
                <div className="sm:hidden block">
                  <a href="https://wa.me/97143994479" target="_blank">
                    <svg
                      width={25}
                      height={26}
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[30px] h-[30px]"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        d="M19.8437 5.61459C18.8887 4.64993 17.7512 3.88508 16.4975 3.36465C15.2438 2.84421 13.8991 2.57862 12.5417 2.58334C6.85416 2.58334 2.21874 7.21876 2.21874 12.9063C2.21874 14.7292 2.69791 16.5 3.59374 18.0625L2.13541 23.4167L7.60416 21.9792C9.11457 22.8021 10.8125 23.2396 12.5417 23.2396C18.2292 23.2396 22.8646 18.6042 22.8646 12.9167C22.8646 10.1563 21.7917 7.56251 19.8437 5.61459ZM12.5417 21.4896C11 21.4896 9.48957 21.0729 8.16666 20.2917L7.85416 20.1042L4.60416 20.9583L5.46874 17.7917L5.26041 17.4688C4.40389 16.101 3.94909 14.5201 3.94791 12.9063C3.94791 8.17709 7.80207 4.32293 12.5312 4.32293C14.8229 4.32293 16.9792 5.21876 18.5937 6.84376C19.3932 7.63956 20.0268 8.58612 20.4577 9.62858C20.8886 10.671 21.1084 11.7887 21.1042 12.9167C21.125 17.6458 17.2708 21.4896 12.5417 21.4896ZM17.25 15.0729C16.9896 14.9479 15.7187 14.3229 15.4896 14.2292C15.25 14.1458 15.0833 14.1042 14.9062 14.3542C14.7292 14.6146 14.2396 15.1979 14.0937 15.3646C13.9479 15.5417 13.7917 15.5625 13.5312 15.4271C13.2708 15.3021 12.4375 15.0208 11.4583 14.1458C10.6875 13.4583 10.1771 12.6146 10.0208 12.3542C9.87499 12.0938 9.99999 11.9583 10.1354 11.8229C10.25 11.7083 10.3958 11.5208 10.5208 11.375C10.6458 11.2292 10.6979 11.1146 10.7812 10.9479C10.8646 10.7708 10.8229 10.625 10.7604 10.5C10.6979 10.375 10.1771 9.10418 9.96874 8.58334C9.76041 8.08334 9.54166 8.14584 9.38541 8.13543H8.88541C8.70832 8.13543 8.43749 8.19793 8.19791 8.45834C7.96874 8.71876 7.30207 9.34376 7.30207 10.6146C7.30207 11.8854 8.22916 13.1146 8.35416 13.2813C8.47916 13.4583 10.1771 16.0625 12.7604 17.1771C13.375 17.4479 13.8542 17.6042 14.2292 17.7188C14.8437 17.9167 15.4062 17.8854 15.8542 17.8229C16.3542 17.75 17.3854 17.1979 17.5937 16.5938C17.8125 15.9896 17.8125 15.4792 17.7396 15.3646C17.6667 15.25 17.5104 15.1979 17.25 15.0729Z"
                        fill="#06A88D"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel className="sm:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                aria-current={item.current ? "page" : undefined}
                className={classNames(
                  item.current
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium"
                )}
              >
                {item.name}
              </DisclosureButton>
            ))}
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
};

export default Navbar;
